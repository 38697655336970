<template>
  <div>
    <we-card title="BANリスト">
      <slot>
        <div>
          <we-table :tableHead="tableHead" :tableData="tableData" :loading="loading" @paging="changePage">
            <template v-slot:toolbar>
              <div md-clearable class="md-toolbar-section-start">
                <we-date-picker
                  id="id_banned_time"
                  type="date"
                  v-model="searchDate"
                  format="YYYY-MM-DD"
                />
              </div>
            </template>
          </we-table>
        </div>
      </slot>
    </we-card>
  </div>
</template>
<script>
import { ajax, common, config } from '../../utils';
export default {
  data() {
    return {
      searchDate: null,
      loading: false,
      tableHead: [
        { name: 'bannedTime', label: '時間' },
        { name: 'owner.nickName', label: 'ニックネーム' },
        { name: 'bannedComment', label: 'BANされた原因' },
      ],
      tableData: [],
      currPage: 1,
      pageSize: config.rowsPerPage,
    }
  },
  created () {
    this.getBanList();
  },
  watch: {
    searchDate: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getBanList();
      }
    },
  },
  methods: {
    getBanList() {
      const vm = this;
      const params = `page=${this.currPage}&size=${this.pageSize}&date=${common.formatDate(this.searchDate, 'YYYY-MM-DD')}`;
      vm.loading = true;
      ajax.get(`${config.api.fan.banlist}?${params}`).then(data => {
        if (vm.currPage > 1 && data.length === 0) {
          vm.currPage -= 1;
        } else {
          vm.tableData = data.map(item => {
            item.bannedTime = common.formatDate(new Date(item.bannedTime), 'YYYY-MM-DD hh:mm:ss');
            return item;
          });
        }
      }).finally(() => vm.loading = false);
    },
    changePage(data) {
      this.currPage = data.currentPage;
      this.pageSize = data.pageSize;
      this.getBanList();
    },
  }
}
</script>